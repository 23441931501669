import LayoutDashboard from "../../widget/LayoutDashboard";
import DiagnosisResultHistory from "../../components/TestingComponents/DiagnosisResultHistory";

const TestingDetailHistory  =() => {
    return(
        <LayoutDashboard>
            <DiagnosisResultHistory />
        </LayoutDashboard>
    )
}
export default TestingDetailHistory;