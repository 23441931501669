import LayoutDashboard from "../../widget/LayoutDashboard";
import AccountListWrapper from "../../components/AccountComponents/AccountListWrapper";

const UserList = () => {
    return(
        <LayoutDashboard>
            <AccountListWrapper />
        </LayoutDashboard>
    )
}
export default UserList;