import LayoutDashboard from "../../widget/LayoutDashboard";
import QuestionDetailComponent from "../../components/QuestionComponent/QuestionDetailComponent/index";

const QuestionDetail = () => {
    return(
        <LayoutDashboard>
            <QuestionDetailComponent/>
        </LayoutDashboard>
    )
}
export default QuestionDetail;