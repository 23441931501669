import LayoutDashboard from "../../widget/LayoutDashboard";
import AccountDetailComponent from "../../components/AccountComponents/AccountDetailComponent";

const UserDetail = () => {
    return(
        <LayoutDashboard>
            <AccountDetailComponent />
        </LayoutDashboard>
    )
}
export default UserDetail;