import LayoutDashboard from "../../widget/LayoutDashboard";
import QuestionComponent from "../../components/QuestionComponent";

const Question = () => {
    return (
        <LayoutDashboard>
            <QuestionComponent />
        </LayoutDashboard>
    )
}
export default Question;