import LayoutDashboard from "../../widget/LayoutDashboard";
import AnswerWrapper from "../../components/AnswerComponents/Wrapper";

const Answer = () => {
    return(
        <LayoutDashboard>
            <AnswerWrapper />
        </LayoutDashboard>
    )
}
export default Answer