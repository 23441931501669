import LayoutDashboard from "../../widget/LayoutDashboard";
import TestingHistoryWrapper from "../../components/TestingComponents/TestingHistory";

const TestingHistory = () => {
    return(
        <LayoutDashboard>
            <TestingHistoryWrapper />
        </LayoutDashboard>
    )
}
export default TestingHistory;