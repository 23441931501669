import LayoutDashboard from "../../widget/LayoutDashboard";
import AnswerDiagnosisDetailComponent from "../../components/AnswerDiagnosisComponents/AnswerDiagnosisDetail";

const AnswerDiagnosisDetail = () => {
    return(
        <LayoutDashboard>
            <AnswerDiagnosisDetailComponent />
        </LayoutDashboard>
    )
}
export default AnswerDiagnosisDetail