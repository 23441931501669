import LayoutDashboard from "../../widget/LayoutDashboard";
import DiagnosisResult from "../../components/TestingComponents/DiagnosisResult";

const TestingDetail = () => {
    return(
        <LayoutDashboard>
            <DiagnosisResult />
        </LayoutDashboard>
    )
}
export default TestingDetail;