import LandingPage from "./components/LandingPage";


const AppRoutes = [
  {
    index: true,
    element: <LandingPage />
  },
];

export default AppRoutes;
