import LayoutDashboard from "../../widget/LayoutDashboard";
import AnswerDetailComponent from "../../components/AnswerComponents/AnswerDetail";
const AnswerDetail = () => {
    return(
        <LayoutDashboard>
            <AnswerDetailComponent />
        </LayoutDashboard>
    )
}
export default AnswerDetail;
