import LayoutDashboard from "../../widget/LayoutDashboard";
import AnswerDiagnosisWrapper from "../../components/AnswerDiagnosisComponents/AnswerDiagnosisWrapper";

const AnswerDiagnosis = () => {
    return(
        <LayoutDashboard>
            <AnswerDiagnosisWrapper />
        </LayoutDashboard>
    )
}
export default AnswerDiagnosis;