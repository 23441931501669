import LayoutDashboard from "../../widget/LayoutDashboard";
import React from "react";
import DiagnosisWrapper from "../../components/DiagnosisComponents/DiagnosisWrapper";

const Diagnosis = () => {
    return(
        <LayoutDashboard>
            <DiagnosisWrapper />
        </LayoutDashboard>
    )
}
export default Diagnosis;