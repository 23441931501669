import LayoutDashboard from "../../widget/LayoutDashboard";
import DoingTesting from "../../components/TestingComponents/DoingTesting";

const Testing = () => {
    return(
        <LayoutDashboard>
            <DoingTesting />
        </LayoutDashboard>
    )
}
export default Testing;