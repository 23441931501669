import LayoutDashboard from "../../widget/LayoutDashboard";
import DiagnosisDetailWrapper from "../../components/DiagnosisComponents/DiagnosisDetail";

const DiagnosisDetail = () => {
    return(
        <LayoutDashboard>
            <DiagnosisDetailWrapper />
        </LayoutDashboard>
    )
}
export default DiagnosisDetail;